<template>
  <div v-loading="loading" class="publish">
    <div class="card inline">
      <DetailsHeader :show-popver="false" />
      <div>
        <el-button type="primary">导出</el-button>
        <el-button type="primary">导出详细榜</el-button>
      </div>
    </div>
    <div class="card">
      <div class="card-title">发布成绩给学生</div>
      <div class="tag">发布学生成绩</div>
      <div class="row">
        <div class="label">自动发布学生成绩：</div>
        <el-switch
          v-model="form.value"
          active-color="#13ce66"
          inactive-color="#C8C8C8"
          active-value="#13ce66"
          inactive-value="#ff4949"
        >
        </el-switch>
      </div>
      <div class="sub-title">
        学生成绩正在计算中，计算完成后<span>自动发布</span>学生成绩
      </div>
      <div class="row">
        <div class="label">自动发布学生成绩：</div>
        <el-switch
          v-model="form.value"
          active-color="#13ce66"
          inactive-color="#C8C8C8"
          active-value="#13ce66"
          inactive-value="#ff4949"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsHeader from "@/components/detailsHeader.vue";
export default {
  name: "Publish",
  components: {
    DetailsHeader,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      form: {
        value: 1,
      },
    };
  },
  mounted() {},
  methods: {
    getData() {},
    sizeChangeHandle(val) {
      this.search.size = val;
      this.getData();
    },
    currentChangeHandle(val) {
      this.search.current = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.publish {
  .inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-title {
    font-size: 16px;
    text-align: center;
    color: #080a09;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .tag {
    color: #080a09;
    box-sizing: border-box;
    font-weight: 600;
    padding: 19px 18px;
    font-size: 14px;
    background: #f2f5f8;
    border: 1px solid #d8d8d8;
    margin-bottom: 26px;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .label {
      color: #080a09;
      font-style: 14px;
    }
  }
  .sub-title {
    color: #080a09;
    margin-bottom: 24px;
    font-style: 14px;
    span {
      color: #ff0000;
    }
  }
}
</style>
